<template>
  <screen_width_cont>
    <div class="cont_300N">
      <div class="nuevoDestinatarioFinalizar">
        <div class="nuevoDestinatarioFinalizarCard">
          <div class="nuevoDestinatarioFinalizarCheck">
            <img :src="iconCotizadorFinalizarCheckAzul" alt="" srcset="" />
          </div>

          <div class="nuevoDestinatarioFinalizarTitle title">
            {{ t("app.destinatario.nuevoDestinatarioFinalizar_tit") }}
          </div>

          <div
            class="nuevoDestinatarioFinalizarText1 text_basico f_w_400"
            v-if="desde == 'beneficiarioedit'"
          >
            {{
              t("app.destinatario.nuevoDestinatarioFinalizar_text2_a")
            }}
            &nbsp;
            <span>{{ dataNewDestinatarioRegistrado.nombre }}</span> &nbsp;
            {{ t("app.destinatario.nuevoDestinatarioFinalizar_text2_b") }}
          </div>
          <div
            class="nuevoDestinatarioFinalizarText1 text_basico f_w_400"
            v-else
          >
            {{
              t("app.destinatario.nuevoDestinatarioFinalizar_text1_a")
            }}
            &nbsp;
            <span>{{ dataNewDestinatarioRegistrado.nombre }}</span> &nbsp;
            {{ t("app.destinatario.nuevoDestinatarioFinalizar_text1_b") }}
          </div>

          <div class="nuevoDestinatarioFinalizarBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.finalizar')"
              @action="finalizar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
// import { IonContent, IonPage } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import { mapState } from "vuex";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "nuevoDestinatarioFinalizar",
  components: {
    btnAction,
    screen_width_cont,
  },
  data() {
    return {
      iconCotizadorFinalizarCheckAzul: require("../assets/imgs/iconVerificaCuponCheckAzul.svg"),
    };
  },
  computed: {
    ...mapState([
      "dataNewDestinatarioRegistrado",
      "menuSpDesde",
      "desde",
      "nav_stack",
      "procesoGeneral",
      "widthScreen",
    ]),

    estadoBtn() {
      let aux = "active";
      return aux;
    },
  },
  methods: {
    finalizar() {
      console.log(
        this.menuSpDesde,
        this.desde,
        this.nav_stack,
        this.procesoGeneral
      );
      //beneficiarioNuevo, beneficiarioedit, beneficiarioedit, beneficiarioedit , (cotizadorDestinatario beneficiarioNuevo)
      if (this.menuSpDesde.desde == "cotizadorDestinatario") {
        this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      } else if (this.desde == "beneficiarioedit") {
        this.$router.push({ name: "destinatariosDetalle", replace: true });
      } else if (this.menuSpDesde.desde == "destinatarios") {
        this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      }else if (this.menuSpDesde.desde == "home") {
        this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      }

      // this.$router.go(-2);
    },
  },
};
</script>

<style>
.nuevoDestinatarioFinalizar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.nuevoDestinatarioFinalizarCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 3.5625rem 8% 3.125rem 8%;
}
.nuevoDestinatarioFinalizarCheck {
  /* margin-top: 3.5625rem; */
  text-align: center;
}
.nuevoDestinatarioFinalizarTitle {
  margin-top: 1.5625rem;

  text-align: center;
  color: #614ad3;
}
.nuevoDestinatarioFinalizarText1 {
  margin-top: 1.5625rem;

  /* font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px; */
  text-align: center;
  color: #787878;
}
.nuevoDestinatarioFinalizarText1 span {
  font-weight: 700;
}
.nuevoDestinatarioFinalizarBtn {
  margin-top: 3.4375rem;
}
</style>